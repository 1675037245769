import DataTables from './modules/datatables';
import ProductDetails from './modules/productDetails';

// make sure to load this after datatables
import './plugins/diacritics-neutralise';

class Report {
  constructor() {
    this.dataTables = new DataTables();
    this.productDetails = new ProductDetails();
  }

  init() {
    this.dataTables.init();
    this.productDetails.init();
  }
}

const report = new Report();

if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', () => {
    report.init();
  });
} else {
  report.init();
}

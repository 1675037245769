/* global wp */

import dt from 'datatables.net';

const { _x } = wp.i18n;

const $ = window.jQuery;
$.fn.DataTable = dt;

export default class DataTables {
  constructor() {
    this.selectors = {
      table: '.js-datatable',
      tableTitle: '.js-datatable-title',
    };

    this.settings = {
      pageOffset: 120,
      scrollSpeed: 300,
    };
  }

  init() {
    this.getDOM();
    this.bindDataTables();
  }

  bindDataTables() {
    Array.from(this.DOM.tables).forEach(table => {
      let offset = 0;
      const $table = $(table);
      $table.DataTable({
        ordering: false,
        dom: '<"dataTables_tools"if>tp',
        pageLength: $table.data('page-length') || 100,
        language: {
          info: _x(
            'Showing _START_ to _END_ of _TOTAL_ notes',
            'datatables',
            'club-o',
          ),
          infoEmpty: _x('No entries to show', 'datatables', 'club-o'),
          infoFiltered: _x(' - from _MAX_ total notes', 'datatables', 'club-o'),
          zeroRecords: _x('No matching notes found', 'datatables', 'club-o'),
          search: '_INPUT_',
          searchPlaceholder: _x('Search...', 'datatables', 'club-o'),
        },
        pagingType: 'numbers',
        preDrawCallback: settings => {
          const api = new $.fn.DataTable.Api(settings);
          const pagination = $table
            .closest('.dataTables_wrapper')
            .find('.dataTables_paginate');
          pagination.toggle(api.page.info().pages > 1);
        },
        drawCallback: settings => {
          // eslint-disable-next-line no-underscore-dangle
          const start = settings._iDisplayStart;
          if (start !== offset) {
            const scrollTop =
              this.DOM.tableTitle.offset().top - this.settings.pageOffset;
            $('html,body').animate({ scrollTop }, this.settings.scrollSpeed);
            offset = start;
          }
        },
      });
    });
  }

  getDOM() {
    this.DOM = {
      tables: document.querySelectorAll(this.selectors.table),
      tableTitle: $(this.selectors.tableTitle),
    };
  }
}
